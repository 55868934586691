import React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '../hooks/use-site-metadata';

type SeoProps = {
  title?: string;
  description?: string;
  pathname?: string;
  children?: string;
};

export const SEO = ({ title, description, pathname, children }: SeoProps) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta
        property="og:site_name"
        content="Unternehmens Digitalisierung - Digital Native Solutions"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <script type="application/ld+json">
        {`
            {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://digitalnativesolutions.de",
            "name": "Digital Native Solutions GmbH",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+49-238-990-378-78",
                "contactType": "Sales"
            }
            }
        `}
      </script>
      {children}
    </Helmet>
  );
};
